<template>
  <div>
    <van-nav-bar
        title="提现"
        left-text="返回"
        right-text="提现记录"
        left-arrow
        fixed
        @click-left="$router.back()"
        @click-right="$router.push('/cashOutRecords')"
    />
    <page-container class="cash-out-container">
      <van-form
          @submit="onSubmit"
          label-width="7.5em"
          input-align="right"
      >
        <div class="cash-out-card-item">
          <div class="amount-title">提现金额</div>
          <van-field
              v-model.trim="form.amount"
              class="amount-item"
              name="amount"
              type="number"
              left-icon="img/money-cn.svg"
              label=""
              label-width="0"
              input-align="left"
              placeholder="请输入金额"
              :rules="[
                { required: true, message: '请输入提现金额' },
                { validator: val => !!+val, message: '请输入提现金额' },
                { pattern: /^([1-9]\d{0,9}|0)(\.\d{1,2})?$/, message: '请输入正确的提现金额' },
            ]"
          />
          <p>余额: {{ balance }}</p>

          <van-field
              v-model.trim="form.bank"
              class="cash-out-info-item"
              name="bankCardNo"
              readonly
              label="收款银行"
          />

          <van-field
              v-model.trim="form.bankCardNo"
              class="cash-out-info-item"
              name="bankCardNo"
              readonly
              label="收款银行卡号"
          />
          <van-field
              v-model.trim="form.fullName"
              class="cash-out-info-item"
              name="fullName"
              readonly
              label="收款人姓名"
          />

          <van-field
              v-model.trim="form.mobilePhone"
              class="cash-out-info-item"
              name="mobilePhone"
              readonly
              label="手机号码"
          />

        </div>

        <div class="submit-container">
          <van-button round block type="primary" native-type="submit">提现</van-button>
        </div>
      </van-form>
    </page-container>
  </div>
</template>

<script>
import { getUserInfo } from '@/api/user';
import { cashOutRecordInfo, cashOutRecordSubmit } from '@/api/cashOut';
import { Dialog, Toast } from 'vant';

const defaultForm = () => ({
  bank: '', // 收款银行
  bankCardNo: '', // 收款银行卡号
  fullName: '', // 收款人姓名
  mobilePhone: '', // 收款人手机
  amount: '', // 提现金额
});

export default {
  name: 'cashOut',
  data() {
    return {
      balance: '', // 用户余额
      showPicker: false,
      form: defaultForm(),
    };
  },
  computed: {},
  created() {
    getUserInfo().then(res => {
      this.balance = res.amount || 0;
    });

    cashOutRecordInfo().then(res => {
      let {
        bankName: bank,
        bankAccountNo: bankCardNo,
        bankAccountName: fullName,
        bankAccountPhone: mobilePhone,
        amount,
      } = res || {};
      this.balance = amount;
      this.form = {
        bank, // 收款银行
        bankCardNo, // 收款银行卡号
        fullName, // 收款人姓名
        mobilePhone, // 收款人手机
      };
    });
  },
  methods: {
    onSubmit() {
      let amount = +this.form.amount;
      let balance = +this.balance;

      if (!balance || amount < balance) {
        return Toast('余额不足');
      }

      Dialog.confirm({
        title: `是否确认提现？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            cashOutRecordSubmit({ amount }, { headers: { getAllResponse: true } }).then(res => {
              done();
              if (res.data.code === 0) {
                Toast(`您已成功提现￥${amount}`);
                this.$router.back();
              } else {
                Toast(res.data.msg);
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },

  },
};
</script>

<style lang="less" scoped>
.cash-out-container {
  top: @nav-bar-height;

  .cash-out-card-item {
    //margin: 10px;
    //border-radius: 15px;
    background-color: #fff;
    overflow: hidden;

    .amount-title {
      margin: 21px 0 44px 17px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }

    .amount-item {
      padding-bottom: 28px;
      font-size: 18px;

      /deep/ .van-field__left-icon .van-icon {
        font-size: 24px;
      }
    }

    .cash-out-info-item {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }

  .submit-container {
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    padding: 18px 14px;
    width: 100%;
  }

  p {
    margin: 16px 0 9px 0;
    padding: 0 14px;
    line-height: 13px;
    font-size: 12px;
    font-weight: bold;
    color: #999;
  }
}
</style>
